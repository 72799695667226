import React from 'react';
import NtfyProvider from './features/contexts/ntfy';
import MainHeaderBar from './components/MainHeaderBar/MainHeaderBar';
import Equipment from './pages/Equipment/Equipment';
import Manifest from './pages/Manifest/Manifest';
import AdasManifest from './pages/AdasManifest/AdasManifest';
import { Routes, Route } from 'react-router-dom';
import RoutingBack from './components/RoutingBack/RoutingsBack';
import Activate from './pages/Activate/Activate';
import SystemInit from './pages/SystemInit/SystemInit';
import SiteSurveys from './pages/SiteSurveys/SiteSurveys';
import Alignments from './pages/Alignments/Alignments';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Home from './components/Home/Home';
import { Typography } from '@mui/material';
import { FetchProvider } from './features/contexts/fetch';

import './App.css';

const pages = [
  {
    name: 'Activate',
    path: '/activate',
    component: Activate,
    secure: false,
    showLink: true,
  },
  {
    name: 'System Initialization',
    path: '/Kunshan/SystemInit',
    component: SystemInit,
    secure: false,
    showLink: false,
  },
  {
    name: 'System Initialization',
    path: '/Kunshan/SystemInit.aspx',
    component: SystemInit,
    secure: false,
    showLink: false,
  },
  {
    name: 'Aligners',
    path: '/aligners',
    component: Equipment,
    secure: true,
    showLink: true,
  },
  {
    name: 'Alignments',
    path: '/alignments',
    component: Alignments,
    secure: true,
    showLink: true,
  },
  {
    name: 'Manifest',
    path: '/manifest',
    component: Manifest,
    secure: true,
    showLink: true,
  },
  {
    name: 'ADAS Manifest',
    path: '/adas_manifest',
    component: AdasManifest,
    secure: true,
    showLink: true,
  },
  {
    name: 'Site Surveys',
    path: '/site_surveys',
    component: SiteSurveys,
    secure: true,
    showLink: false,
  },
];

// @ts-ignore
const ProtectedComponent = ({ component, ...propsForComponent}) => {
  const Cp = withAuthenticationRequired(component);
  return <Cp {...propsForComponent} />
}

function App(): React.JSX.Element {
  const getRouteElement = (page: any) => {
    if (page.secure) {
      return (
        <ProtectedComponent component={page.component} />
      )
    } else {
      return <page.component />;
    }
  }

  return (
    <FetchProvider>
      <NtfyProvider>
        <RoutingBack>
          <>
            <MainHeaderBar pages={pages}/>
            <Routes>
              <Route path="/" element={<Home/>} />
                {
                  pages
                    .map((cur, index) => {
                      return (
                        <Route
                          key={`${cur.name.toLowerCase().replace(' ', '_')}-${index}`}
                          path={cur.path}
                          element={getRouteElement(cur)}
                        />
                      )
                    })
                }
            </Routes>
            <Typography style={{padding:'0 30px 0 30px'}} variant="caption">Aligner Web Portal - Version 1.1.5</Typography>
          </>
        </RoutingBack>
      </NtfyProvider>
    </FetchProvider>
  );
}

export default App;
