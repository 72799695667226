/**
 * @copyright Snapon
 */

import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { TNtfy } from '../../_types/ntfy';
import * as Time from '../../utils/time';


/**act
 */
interface ISliceNtfy {
  timeoutShort: number,
  timeoutLong: number,

  preventDuplicate: boolean,
  hideInfo: boolean,

  list: Array<TNtfy>,

  /// @note if we choose to have different lists
  // listShort: Array<TNtfy>,
  // listLong: Array<TNtfy>,
  // listClick: Array<TNtfy>,
}

/**
 */
const defaultState : ISliceNtfy = {
  timeoutShort: 1*1000,
  timeoutLong: 60*1000,

  preventDuplicate: false,
  hideInfo: false,

  list: [],

  /// @note if we choose to have different lists
  // listShort: [],
  // listLong: [],
  // listClick: [],
}

/**
 */
const NtfyTimeout = createAsyncThunk(
    'ntfy/timeout',
    async (ntfy_obj: TNtfy, {dispatch, getState}) => {
      const gs: any = getState()

      if( 'short' === ntfy_obj.life )
      {
        await Time.wait( gs.ntfy.timeoutShort )
      }
      else if( 'long' === ntfy_obj.life )
      {
        await Time.wait( gs.ntfy.timeoutLong )
      }
      else
      {
        //
      }

      return ntfy_obj
    }
) // NtfyTimeout

/**
 */
export const NtfyReport = createAsyncThunk(
    'ntfy/report',
    async (ntfy_obj: TNtfy, {dispatch, getState}) => {
      const gs: any = getState()

      if( 'short' === ntfy_obj.life || 'long' === ntfy_obj.life )
      {
        dispatch( NtfyTimeout( ntfy_obj ) ).catch( console.warn )
      }

      if( gs.ntfy.preventDuplicate && gs.ntfy.list.some( (x: TNtfy) => (x.msg === ntfy_obj.msg && x.t === ntfy_obj.t) ) )
      {
        return Promise.reject( new Error('Duplicate message') )
      }

      return ntfy_obj
    }
) // NtfyReport

/**
 */
const slice = createSlice( {
  name: 'Ntfy',
  initialState: defaultState,
  reducers: {
    NtfyTimeoutShort: (state: ISliceNtfy, action) => {
      state.timeoutShort = action.payload
    },
    NtfyTimeoutLong: (state: ISliceNtfy, action) => {
      state.timeoutLong = action.payload
    },
    NtfyHideInfo: (state: ISliceNtfy, action) => {
      state.hideInfo = action.payload
    },
    NtfyRemove: (state: ISliceNtfy, action) => {
      const ntfy_obj: TNtfy = action.payload
      state.list = state.list.filter( x => x.ts !== ntfy_obj.ts )

      /// @note if we choose to have different lists
      // switch( ntfy_obj.life )
      // {
      // case 'short':
      //   state.listShort = state.listShort.filter( x => x.ts !== ntfy_obj.ts )
      //   break
      // case 'long':
      //   state.listLong = state.listLong.filter( x => x.ts !== ntfy_obj.ts )
      //   break
      // case 'click':
      //   state.listClick = state.listClick.filter( x => x.ts !== ntfy_obj.ts )
      //   break
      // default:
      //   // do nothing!!!
      //   break
      // } // switch
    },
    NtfyShown: (state: ISliceNtfy, action) => {
      state.list = state.list.map( x => {
        return action.payload.ts === x.ts ? {...x, shown: action.payload.val} : x
      } )
    },
    NtfyShownBatch: (state: ISliceNtfy, action) => {
      state.list = state.list.map( x => {
        if( x.shown )
        {
          return x
        }

        if( action.payload.list.some( (y: TNtfy) => y.ts === x.ts ) )
        {
          x.shown = action.payload.val
        }

        return x
      } )
    },
    NtfySeen: (state: ISliceNtfy, action) => {
      state.list = state.list.map( x => {
        return action.payload.ts === x.ts ? {...x, seen: action.payload.val} : x
      } )
    },
  },

  extraReducers: (builder) => {
    builder.addCase(NtfyTimeout.fulfilled, (state: ISliceNtfy, action: any) => {
      const ntfy_obj: TNtfy = action.payload
      state.list = state.list.filter( x => x.ts !== ntfy_obj.ts )

      /// @note if we choose to have different lists
      // switch( ntfy_obj.life )
      // {
      // case 'short':
      //   state.listShort = state.listShort.filter( x => x.ts !== ntfy_obj.ts )
      //   break
      // case 'long':
      //   state.listLong = state.listLong.filter( x => x.ts !== ntfy_obj.ts )
      //   break
      // case 'click':
      //   state.listClick = state.listClick.filter( x => x.ts !== ntfy_obj.ts )
      //   break
      // default:
      //   // do nothing!!!
      //   break
      // } // switch
    })

    builder.addCase(NtfyReport.fulfilled, (state: ISliceNtfy, action: any) => {
      const ntfy_obj: TNtfy = action.payload
      state.list.push( ntfy_obj )

      /// @note if we choose to have different lists
      // switch( ntfy_obj.life )
      // {
      // case 'short':
      //   state.listShort.push( ntfy_obj )
      //   break
      // case 'long':
      //   state.listLong.push( ntfy_obj )
      //   break
      // case 'click':
      //   state.listClick.push( ntfy_obj )
      //   break
      // default:
      //   // do nothing!!!
      //   break
      // } // switch
    })
    // builder.addCase(NtfyReport.rejected, (state: ISliceNtfy, action: any) => {
    //   // console.warn( 'NtfyReport.rejected: action: ', action )
    // })
  },
} );

export const rdxNtfy: any = (rootState: any) => rootState.ntfy;

export const rdxNtfyTimeoutShort = createSelector(
    rdxNtfy,
    (ntfy: ISliceNtfy) => ntfy.timeoutShort
);

export const rdxNtfyTimeoutLong = createSelector(
    rdxNtfy,
    (ntfy: ISliceNtfy) => ntfy.timeoutLong
);

export const rdxNtfyHideInfo = createSelector(
    rdxNtfy,
    (ntfy: ISliceNtfy) => ntfy.hideInfo
);

export const rdxNtfyList = createSelector(
    rdxNtfy,
    (ntfy: ISliceNtfy) => ntfy.list
);

/// @note if we choose to have different lists
// export const rdxNtfyShort = createSelector(
//   rdxNtfy,
//   (ntfy: ISliceNtfy) => ntfy.listShort
// );
// export const rdxNtfyLong = createSelector(
//   rdxNtfy,
//   (ntfy: ISliceNtfy) => ntfy.listLong
// );
// export const rdxNtfyClick = createSelector(
//   rdxNtfy,
//   (ntfy: ISliceNtfy) => ntfy.listClick
// );

/**
 */
export const {
  NtfyTimeoutShort,
  NtfyTimeoutLong,
  NtfyHideInfo,
  NtfyRemove,
  NtfyShown,
  NtfyShownBatch,
  NtfySeen,
} = slice.actions;

/**
 */
export default slice.reducer;



